// Import our custom CSS
import '../styles/_main.scss'

// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'
import AOS from 'aos';



//Or comment out the above and add individual components

//import { Tooltip, Toast, Popover } from 'bootstrap'


/************** OTHER JS HERE ***************
*********************************************/

AOS.init({
    duration: 1000,

});